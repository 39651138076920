import { gsap } from 'gsap';

export default () => {
  (function () {
    const headings = document.querySelectorAll('.accordion__header');

    Array.prototype.forEach.call(headings, (h) => {
      let btn = h.querySelector('button');
      let target = h.querySelector('.content');
      let tlAccordion = gsap.timeline({ paused: true });

      tlAccordion
        .fromTo(
          target,
          { height: 0 },
          {
            duration: 0.5,
            height: 'auto',
            ease: 'back.inOut(1)',
          }
        )
        .fromTo(
          target,
          { y: 10, opacity: 0 },
          { duration: 0.8, y: 0, opacity: 1, ease: 'back.inOut(1)' },
          '-=0.5'
        );
      tlAccordion.reverse();

      btn.onclick = () => {
        let expanded = btn.getAttribute('aria-expanded') === 'true';

        tlAccordion.reversed(!tlAccordion.reversed());

        btn.setAttribute('aria-expanded', !expanded);
        target.setAttribute('aria-expanded', !expanded);
      };
    });
  })();
};
