import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger);
// gsap.registerPlugin(SplitText);

export default () => {
  // Single item transitions
  gsap.utils.toArray('.is-animated').forEach(function (animatedItem) {
    let slideAmount =
      animatedItem.dataset.scrollslide != null
        ? animatedItem.dataset.scrollslide
        : 40;

    let scrollDelay =
      animatedItem.dataset.scrolldelay != null
        ? animatedItem.dataset.scrolldelay
        : 0;

    gsap.set(animatedItem, {
      autoAlpha: 0,
      y: slideAmount,
    });

    gsap.to(animatedItem, {
      scrollTrigger: animatedItem,
      delay: scrollDelay,
      autoAlpha: 1,
      y: 0,
      duration: 1.5,
      ease: 'expo',
    });
  });

  // Staggered fade-in-and-up
  gsap.utils.toArray('.animate-contents>*').forEach((fader) => {
    let slideAmount =
      fader.dataset.scrollslide != null ? fader.dataset.scrollslide : 40;
    gsap.set(fader, {
      autoAlpha: 0,
      y: slideAmount,
    });
  });

  ScrollTrigger.batch('.animate-contents', {
    onEnter: (batch) => {
      batch.forEach((section, i) => {
        gsap.to(section.querySelectorAll('.animate-contents>*'), {
          autoAlpha: 1,
          y: 0,
          duration: 0.6,
          ease: 'back',
          stagger: 0.1,
          delay: i * 0.08,
        });
      });
    },
    start: 'top 80%',
  });
};
