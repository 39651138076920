export default () => {
  // * ServiceSwitcher™

  const toggleButtons = document.querySelectorAll('.toggle');
  const toggleContents = Array.from(
    document.querySelectorAll('.toggle--content')
  );

  function handleToggleClick(event) {
    // hide all toggle panels
    toggleContents.forEach((content) => {
      content.hidden = true;
    });
    // mark all toggles as unselected
    toggleButtons.forEach((toggle) => {
      // toggle.ariaSelected = false;

      toggle.setAttribute('aria-selected', false);
    });
    // mark the clicked toggle as selected
    event.currentTarget.setAttribute('aria-selected', true);

    // find the associated togglecontent and show it!
    const { id } = event.currentTarget;

    // find in the array of toggleContents
    const toggleContent = toggleContents.filter(
      (content) => content.getAttribute('aria-labelledby') === id
    );

    toggleContent.forEach((toggle) => {
      toggle.hidden = false;
    });
  }

  toggleButtons.forEach((button) =>
    button.addEventListener('click', handleToggleClick)
  );
};
