export default () => {
  // * Flyout Menu
  const navButton = document.querySelector('.menu__toggle');
  const nav = document.querySelector('#js-menu__overlay');
  const navButtonLabel = document.querySelector('.menu__label');

  nav.setAttribute('aria-expanded', false);

  navButton.addEventListener(
    'click',
    function (event) {
      document.body.classList.toggle('menu-open');

      // Prevent default link behavior
      event.preventDefault();

      var expandedValue = navButton.getAttribute('aria-expanded');
      expandedValue = expandedValue == 'true' ? 'false' : 'true';
      navButton.setAttribute('aria-expanded', expandedValue);
      nav.setAttribute('aria-expanded', expandedValue);

      //swap label text
      if (
        navButtonLabel.getAttribute('data-text-swap') ==
        navButtonLabel.innerHTML
      ) {
        navButtonLabel.innerHTML =
          navButtonLabel.getAttribute('data-text-original');
      } else {
        navButtonLabel.setAttribute(
          'data-text-original',
          navButtonLabel.innerHTML
        );
        navButtonLabel.innerHTML =
          navButtonLabel.getAttribute('data-text-swap');
      }
    },
    false
  );
};
