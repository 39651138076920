import flyoutMenu from '../components/flyout-menu.js';
import serviceSwitcher from '../components/services-switcher.js';
import accordions from '../components/accordions.js';
import ARIAmodal from 'aria-modal-dialog';
import transitions from '../components/transitions.js';

export default {
  init() {
    // JavaScript to be fired on all pages
    ARIAmodal;

    flyoutMenu();
    transitions();
    accordions();
    serviceSwitcher();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
